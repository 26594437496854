<template>
  <div class="big-data-container">
    <div class="top-search-box p-b-0 m-b-20">
      <el-form label-width="80px">
            <el-row>
              <el-col :span="span">
                <el-form-item label="用户名称">
                  <el-input v-model="searchParams.username" clearable size="mini" placeholder="请输入用户名称" @change="onSearch" />
                </el-form-item>
              </el-col>
              <el-col :span="span">
                <el-form-item label="用户状态">
                  <el-select v-model="searchParams.status" clearable size="mini" placeholder="请选择用户状态" @change="onSearch">
                    <el-option v-for="check in checkList" :key="check.value" :label="check.label" :value="check.value"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <business-search  :span="span" v-if="user.tenantId === 0" :dataObj="searchParams" @change="onSearch" />
              <el-col :span="24 - span * spanCnt" class="right-btns">
                <el-button class="search-box-btn" size="mini" type="primary" icon="el-icon-search" @click="onSearch">查询</el-button>
                <el-button class="search-box-btn" type="primary" size="mini" icon="el-icon-circle-plus-outline" @click="onAddUser">添加帐号</el-button>
              </el-col>
            </el-row>
          </el-form>
    </div>
    <div class="content-list-data-box">
      <el-table :data="dataList" style="width: 100%" :height="tableHeight"
              :header-cell-style="{ background: '#F7F7F7', color: '#2d2d2d', 'text-align': 'center' }">
              <el-table-column type="index" label="序号" width="50" align="center">
                <template slot-scope="scope">
                  {{ (scope.$index + 1) + (pageData.current - 1) * pageData.size }}
                </template>
              </el-table-column>
              <el-table-column prop="username" label="用户名">
                <template slot-scope="scope">
                  <el-link type="primary" :href="`/#/user/detail/${scope.row.id}`">{{ scope.row.username }}</el-link>
                </template>
              </el-table-column>
              <el-table-column prop="grade" label="用户等级" width="85" align="center">
                <template slot-scope="scope">
                  <span>{{ getUserGrade(scope.row) }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="tenantId" label="公司分类" width="85" align="center">
                <template slot-scope="scope">
                  <span>{{ roleTypes[scope.row.tenantId].label }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="companyName" label="公司名称"></el-table-column>
              <el-table-column prop="mobile" label="手机号" width="120" align="center"></el-table-column>
              <el-table-column prop="creator" label="创建人" width="120" align="center"></el-table-column>
              <el-table-column prop="status" label="用户状态" width="100" align="center">
                <template slot-scope="scope">
                  <el-tag effect="dark" size="small" v-if="scope.row.status === 0" type="success">正常</el-tag>
                  <el-tag effect="dark" size="small" v-if="scope.row.status === 1" type="warning">待审核</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="roleId" label="独立用户" width="100" align="center">
                <template slot-scope="scope">
                  <span>{{ isSelfUser(scope.row) }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="roleId" label="操作权限" width="100" align="center">
                <template slot-scope="scope">
                  <el-tag effect="dark" size="small" v-if="scope.row.roleId === 0" type="success">正常</el-tag>
                  <el-tag effect="dark" size="small" v-else type="info">只读</el-tag>
                </template>
              </el-table-column>
              <el-table-column label="操作" width="120" align="center">
                <template slot-scope="scope">
                  <div v-if="scope.row.status === 1">
                    <el-button type="warning" size="mini" icon="el-icon-circle-check" @click="checkUser(scope.row)">审核用户</el-button>
                  </div>
                  <div v-else>无操作内容</div>
                </template>
              </el-table-column>
            </el-table>
            <el-pagination class="text-right m-t-10" background layout="sizes, prev, pager, next, jumper"
              :page-sizes="[20, 50, 100]" @size-change="onSizeChange"
              :page-size="pageData.size" :total="pageData.total" :current-page="pageData.current" @current-change="pageChange" />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import userApi from "@/api/user";
import bizApi from "@/api/business";

import BusinessSearch from "@/components/business/search";

const { decChangeScale, binaryAppendZero, binaryToDec } = $numberMatrixing;

let searchParams = {
  username: "",
  tenantId: "",
  businessId: ""
}
let pageData = {
  current: 1,
  total: 0,
  size: 20
};

const protoSearchObj = JSON.stringify(searchParams);
const protoPageData = JSON.stringify(pageData);

export default {
  components: {
    BusinessSearch
  },
  data() {
    return {
      span: 4,
      spanCnt: 2,
      utils: $utils,
      isSmallScreen: false,
      currentPage: 1,
      dialogVisible: {
        edit: false,
        mix: false,
        password: false
      },
      loading: false,
      searchParams: {},
      pageData: pageData,
      roleTypes: $statics.ROLE_GROUP_LIST,
      companyDisabled: true,
      centerDialogVisible: false,
      companyList: [],
      checkList: [
        {
          label: "正常",
          value: 0
        },
        {
          label: "审核中",
          value: 1
        }
      ],
      dataList: [],
      row: null
    };
  },
  computed: {
    ...mapGetters(['user'])
  },
  created () {
    console.log("user");
    this.searchParams = searchParams;
    this.spanCnt = this.user.tenantId === 0 ? 4: 2;

    this.getDatas();
  },
  mounted () {
    this.$route.meta.deactivated = () => {
      this.pageData = pageData = JSON.parse(protoPageData);
      this.searchParams = searchParams = JSON.parse(protoSearchObj);
    }
    this.tableHeight = document.body.scrollHeight - 220;
  },
  methods: {
    onSizeChange (e) {
      pageData.size = e;
      pageData.current = 1;
      this.getDatas();
    },
    pageChange(e) {
      pageData.current = e;
      this.getDatas();
    },
    async getDatas () {
      try {
        this.loading = true;
        const current = pageData.current;

        const data = Object.assign({
          page:  (current - 1) * pageData.size,
          size: pageData.size,
        }, searchParams);

        let res = [];
        let total = 0;
        if (this.user.tenantId !== 0) {
          /*
            [20240416]
            因为现在数据库 staffCode 会把包含所有上级公司的 id 全部拼起来
            所以当登录用户不是超级管理员是，需要通过 检索 subsidiaryId 中含有当前登录用户的 businessid 的数据
           */
          data.staffCode = `${this.user.businessId},`;
          let isSelfUser = this.user.self === 1;
          total = isSelfUser ? 1: 0;
          // 如果是独立用户，先找到自己
          if (isSelfUser && pageData.current === 1) {
            // 如果是独立用户，先找自己，再找其他人
            const tmp = Object.assign({}, data);
            tmp.username = this.user.username;
            const res0 = await userApi.list(tmp);
            res = res0.data;

            data.size -= 1;
            data.creator = this.user.username;
          }
        }

        const res1 = await userApi.list(data);
        res = res.concat(res1.data);

        total += res1.paging.total;
        this.dataList = res;
        pageData.total = total;
      } catch (e) {
        console.error(e);
        this.$message.error("读取失败");

        this.pageData = pageData = {
          current: 1,
          total: 0,
          size: pageData.size
        };
        this.dataList = [];
      } finally {
        this.loading = false;
      }
    },
    onSearch () {
      pageData.current = 1;
      this.getDatas();
    },
    onAddUser () {
      this.$router.push({
        path: "/user/add"
      });
    },
    getUserGrade ({grade}) {
      let binGrade = decChangeScale(grade, 2);
      binGrade = binaryAppendZero(binGrade, 5);
      return binaryToDec(binGrade.slice(2));
    },
    isSelfUser ({grade}) {
      let binGrade = decChangeScale(grade, 2);
      binGrade = binaryAppendZero(binGrade, 5);
      return binGrade.charAt(0) === "1" ? "是" : "否";
    },
    checkUser (row) {
      this.$confirm('您确定要通过该用户的审核么？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        try {
          this.loading = true;
          const data = Object.assign({}, row);
          data.status = 0; // 审核状态
          data.roleId = 0; // 只读权限

          const res = await bizApi.detail(row.businessId);
          if (!res) {
            this.$message.error("当前用户和公司信息无法匹配，请确认");
            return;
          } else {
            this.$message.success("审核通过");
          }
          res.configs = $statics.DEFAULT_AUTHORITY_CONFIGS;

          try {
            await userApi.edit(data);
            await bizApi.edit(res);
            this.getDatas();
          } catch (e) {
            this.$message.error("审核失败");
            return;
          } finally {
          }
        } catch (e) {
          this.$message.error(e.response.data.message);
          return;
        } finally {
          this.loading = false;
        }
      });
    },
  }
};
</script>