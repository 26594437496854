var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "big-data-container" }, [
    _c(
      "div",
      { staticClass: "top-search-box p-b-0 m-b-20" },
      [
        _c(
          "el-form",
          { attrs: { "label-width": "80px" } },
          [
            _c(
              "el-row",
              [
                _c(
                  "el-col",
                  { attrs: { span: _vm.span } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "用户名称" } },
                      [
                        _c("el-input", {
                          attrs: {
                            clearable: "",
                            size: "mini",
                            placeholder: "请输入用户名称",
                          },
                          on: { change: _vm.onSearch },
                          model: {
                            value: _vm.searchParams.username,
                            callback: function ($$v) {
                              _vm.$set(_vm.searchParams, "username", $$v)
                            },
                            expression: "searchParams.username",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  { attrs: { span: _vm.span } },
                  [
                    _c(
                      "el-form-item",
                      { attrs: { label: "用户状态" } },
                      [
                        _c(
                          "el-select",
                          {
                            attrs: {
                              clearable: "",
                              size: "mini",
                              placeholder: "请选择用户状态",
                            },
                            on: { change: _vm.onSearch },
                            model: {
                              value: _vm.searchParams.status,
                              callback: function ($$v) {
                                _vm.$set(_vm.searchParams, "status", $$v)
                              },
                              expression: "searchParams.status",
                            },
                          },
                          _vm._l(_vm.checkList, function (check) {
                            return _c("el-option", {
                              key: check.value,
                              attrs: { label: check.label, value: check.value },
                            })
                          }),
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm.user.tenantId === 0
                  ? _c("business-search", {
                      attrs: { span: _vm.span, dataObj: _vm.searchParams },
                      on: { change: _vm.onSearch },
                    })
                  : _vm._e(),
                _c(
                  "el-col",
                  {
                    staticClass: "right-btns",
                    attrs: { span: 24 - _vm.span * _vm.spanCnt },
                  },
                  [
                    _c(
                      "el-button",
                      {
                        staticClass: "search-box-btn",
                        attrs: {
                          size: "mini",
                          type: "primary",
                          icon: "el-icon-search",
                        },
                        on: { click: _vm.onSearch },
                      },
                      [_vm._v("查询")]
                    ),
                    _c(
                      "el-button",
                      {
                        staticClass: "search-box-btn",
                        attrs: {
                          type: "primary",
                          size: "mini",
                          icon: "el-icon-circle-plus-outline",
                        },
                        on: { click: _vm.onAddUser },
                      },
                      [_vm._v("添加帐号")]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "content-list-data-box" },
      [
        _c(
          "el-table",
          {
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.dataList,
              height: _vm.tableHeight,
              "header-cell-style": {
                background: "#F7F7F7",
                color: "#2d2d2d",
                "text-align": "center",
              },
            },
          },
          [
            _c("el-table-column", {
              attrs: {
                type: "index",
                label: "序号",
                width: "50",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _vm._v(
                        " " +
                          _vm._s(
                            scope.$index +
                              1 +
                              (_vm.pageData.current - 1) * _vm.pageData.size
                          ) +
                          " "
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "username", label: "用户名" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c(
                        "el-link",
                        {
                          attrs: {
                            type: "primary",
                            href: `/#/user/detail/${scope.row.id}`,
                          },
                        },
                        [_vm._v(_vm._s(scope.row.username))]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "grade",
                label: "用户等级",
                width: "85",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [_vm._v(_vm._s(_vm.getUserGrade(scope.row)))]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "tenantId",
                label: "公司分类",
                width: "85",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [
                        _vm._v(_vm._s(_vm.roleTypes[scope.row.tenantId].label)),
                      ]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { prop: "companyName", label: "公司名称" },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "mobile",
                label: "手机号",
                width: "120",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "creator",
                label: "创建人",
                width: "120",
                align: "center",
              },
            }),
            _c("el-table-column", {
              attrs: {
                prop: "status",
                label: "用户状态",
                width: "100",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.status === 0
                        ? _c(
                            "el-tag",
                            {
                              attrs: {
                                effect: "dark",
                                size: "small",
                                type: "success",
                              },
                            },
                            [_vm._v("正常")]
                          )
                        : _vm._e(),
                      scope.row.status === 1
                        ? _c(
                            "el-tag",
                            {
                              attrs: {
                                effect: "dark",
                                size: "small",
                                type: "warning",
                              },
                            },
                            [_vm._v("待审核")]
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "roleId",
                label: "独立用户",
                width: "100",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      _c("span", [_vm._v(_vm._s(_vm.isSelfUser(scope.row)))]),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: {
                prop: "roleId",
                label: "操作权限",
                width: "100",
                align: "center",
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.roleId === 0
                        ? _c(
                            "el-tag",
                            {
                              attrs: {
                                effect: "dark",
                                size: "small",
                                type: "success",
                              },
                            },
                            [_vm._v("正常")]
                          )
                        : _c(
                            "el-tag",
                            {
                              attrs: {
                                effect: "dark",
                                size: "small",
                                type: "info",
                              },
                            },
                            [_vm._v("只读")]
                          ),
                    ]
                  },
                },
              ]),
            }),
            _c("el-table-column", {
              attrs: { label: "操作", width: "120", align: "center" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (scope) {
                    return [
                      scope.row.status === 1
                        ? _c(
                            "div",
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "warning",
                                    size: "mini",
                                    icon: "el-icon-circle-check",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.checkUser(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("审核用户")]
                              ),
                            ],
                            1
                          )
                        : _c("div", [_vm._v("无操作内容")]),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _c("el-pagination", {
          staticClass: "text-right m-t-10",
          attrs: {
            background: "",
            layout: "sizes, prev, pager, next, jumper",
            "page-sizes": [20, 50, 100],
            "page-size": _vm.pageData.size,
            total: _vm.pageData.total,
            "current-page": _vm.pageData.current,
          },
          on: {
            "size-change": _vm.onSizeChange,
            "current-change": _vm.pageChange,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }